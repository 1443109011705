import React, { useState } from 'react'
import {
  ClientSolutionAndSummary,
  ImageBlock,
  Introduction,
  MultipleImageBlock,
  TextBlock,
  Citation,
  Anchor,
} from '../../../components/molecules/Cases'
import {
  Mouse,
  WebPage,
  Calendar,
  HandSocial,
  Megaphone,
} from '../../../components/molecules/Icons'

import SectionContainer from '../../../components/molecules/SectionContainer'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import LocTokContent from './content.json'

import './styles.scss'

const { clientSolutionAndSummary, accordionData } = LocTokContent

const Content = () => {
  const Intro = () => {
    return (
      <>
        <Introduction Title={() => <b>LocTok</b>}>
          Uma forma 100% on-line de alugar utensílios, móveis e acessórios para eventos de
          todos os portes
        </Introduction>

        <ClientSolutionAndSummary
          client={clientSolutionAndSummary.client}
          solutions={clientSolutionAndSummary.solutions}
          brand={clientSolutionAndSummary.brand}
          summary={clientSolutionAndSummary.summary}
        />

        <MultipleImageBlock
          images={[
            'loctok-introduction-1.webp',
            'loctok-introduction-2.webp',
            'loctok-introduction-3.webp',
          ]}
          alt="Eventos produzidos com os móveis e utensílios da LocTok | Reprodução: Instagram"
        />
      </>
    )
  }

  const GetToKnowUs = () => {
    return (
      <div id="conhecendo">
        <TextBlock hasMargin title="Conhecendo a LocTok">
          A empresa possui forte atuação no setor de eventos por meio da locação de
          móveis, louças, talheres, acessórios e utensílios para todos os tipos e tamanhos
          de festas e eventos por curtos períodos de tempo.
          <br />
          <br />
          Além de todo o conhecimento que a marca possui sobre o segmento, os clientes
          também contam com um inventário de mais de 40 mil peças para serem alugados de
          acordo com a disponibilidade. Além disso, a empresa também está pronta para
          atender a diferentes tipos de celebrações, desde festas juninas até casamentos.
        </TextBlock>

        <ImageBlock alt="Reprodução site" image="loctok-mockup.webp" />
      </div>
    )
  }

  const ProjectSteps = () => {
    const [activeTab, setActiveTab] = useState(-1)
    const [width] = useWindowDimensions()
    function handleTab(index) {
      if (width > 769) return

      setActiveTab((previousTab) => {
        return previousTab === index ? -1 : index
      })
    }

    return (
      <div id="quaisforam">
        <SectionContainer>
          <h3 className="loctok__subtitle">Quais foram as etapas do projeto?</h3>

          <div className="loctok__accordion">
            {accordionData.map(({ title, listItems }, index) => (
              <div
                className={`loctok__accordion__card ${
                  index === activeTab ? 'loctok__accordion__card--active' : ''
                }`}
              >
                <span onClick={() => handleTab(index)}>{title}</span>

                <ul>
                  {listItems.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </SectionContainer>
        <ImageBlock
          image="loctok-projectSteps.webp"
          alt="Exemplos de produtos disponíveis para locação no e-commerce | Reprodução site"
        />
      </div>
    )
  }

  const DigitalEngagement = () => {
    return (
      <SectionContainer>
        <h3 id="areasenvolvidas" className="loctok__subtitle">
          Quais foram as áreas da Digital Republic envolvidas no projeto?
        </h3>

        <div className="loctok__digital-engagement__content">
          <div className="loctok__digital-engagement__content__card">
            <Mouse />

            <span>Design</span>
          </div>
          <div className="loctok__digital-engagement__content__card">
            <WebPage />

            <span>Desenvolvimento Front-End e Back-End</span>
          </div>
        </div>
      </SectionContainer>
    )
  }

  const LocTokAdvantages = () => {
    return (
      <div id="diferencial">
        <TextBlock
          className="loctok__differential"
          hasMargin
          title=" Qual é o diferencial do e-commerce da LocTok no segmento de eventos?"
        >
          Considere a sequência padrão de uma loja virtual: abrir o site {'> '}
          pesquisar o catálogo {'>'} encher o carrinho com os produtos escolhidos {'> '}
          realizar o pagamento para finalizar a compra {'>'} receber os produtos {'> '}
          fim.
          <br />
          <br />
          No caso da LocTok, após a etapa de recebimento dos produtos alugados, entra a
          devolução deles para a empresa.{' '}
          <b>
            O novo site consegue controlar a locação dos itens disponíveis em estoque, de
            forma que seja gerenciada a entrada e a saída e a disponibilidade de cada item
            de acordo com a data.
          </b>
          <br />
          <br />
          Em resumo:{' '}
          <b>
            se dois clientes acessarem o site ao mesmo tempo, é possível alugar a mesma
            mesa para hoje e semana que vem.
          </b>{' '}
          Muito simples, concorda?
          <br />
          <br />
          <Anchor href="https://www.loctok.com.br">Conheça o site da LocTok</Anchor>
        </TextBlock>

        <ImageBlock
          image="loctok-advantages.webp"
          alt="Evento produzido com os móveis e utensílios da LocTok | Reprodução site"
        />
      </div>
    )
  }

  const Results = () => {
    return (
      <div id="resultados">
        <SectionContainer className="loctok__results">
          <h3 className="loctok__subtitle">
            Quais foram os resultados alcançados após o lançamento da loja virtual?
          </h3>

          <div className="loctok__results__content">
            <div className="loctok__results__content__card">
              <HandSocial />

              <span>
                A LocTok passou a alcançar um novo público que ainda não conhecia a marca
              </span>
            </div>

            <div className="loctok__results__content__card">
              <Megaphone />

              <span>
                Posicionamento digital da empresa fortalecido, com vendas acontecendo 100%
                on-line
              </span>
            </div>

            <div className="loctok__results__content__card">
              <Calendar />

              <span>Mais de 120 novas locações mensais</span>
            </div>
          </div>

          <Anchor href="/services" target="_self" title="Serviços">
            Venha alcançar resultados com a Digital Republic
          </Anchor>
        </SectionContainer>
      </div>
    )
  }

  const WhatLocTokSays = () => {
    return (
      <div id="oqueloctokdiz">
        <SectionContainer>
          <h3 className="loctok__subtitle">
            O que diz a LocTok sobre o projeto executado pela Digital Republic?
          </h3>
        </SectionContainer>
        <Citation className="loctok__citation">
          <p>
            Fizemos a reformulação e transição de nosso site para e-Commerce com a Digital
            Republic. Tivemos todo o apoio durante o projeto, desde o briefing passando
            por implantação, lançamento e suporte posterior, o que nos ajudou muito a
            navegar com maior facilidade pelo universo digital. Nossas demandas sempre
            foram atendidas com muita agilidade e presteza. A parceria com a Digital
            Republic foi fundamental para o sucesso do projeto.
          </p>
          <p>
            A parceria com a Digital Republic foi fundamental para o sucesso do projeto."
          </p>
          <p>
            <b>Guilherme Franco |</b> Sócio Diretor na Loctok
          </p>
        </Citation>
      </div>
    )
  }

  return (
    <>
      <Intro />
      <GetToKnowUs />
      <ProjectSteps />
      <DigitalEngagement />
      <LocTokAdvantages />
      <Results />
      <WhatLocTokSays />
    </>
  )
}

export default Content
